import React from 'react'

function RightBanner() {
  return (
    <>
    <div className='right-banner'>
    <div className='right-banner-text'>
    <p>
    Welcome to our project! This project provides a snapshot of the Kindle eBook market, captured weekly during the May/June period of 2023, and a resulting analysis and (theoretically) sellable final product. We've investigated the wider market trends, splitting our analysis into two parts: a broad overview by category and a more in-depth look at subcategory level data.</p>
       <br></br>
<p>Our main objective with this project was to emulate the process of developing an effective product for a data science business operating in the eBook industry. We wanted to create something that could be of service to authors, publishers, ad-copy writers, and various others. Beyond just data analysis and insights, our focus was to create a product that was competitive with existing solutions.</p>
<br></br>
<p>This is a joint project by Matt Kownacki and George Jeffreys. You are currently viewing the portfolio version of our project. Feel free to explore the <a href="https://github.com/mmk150/ebookds">public Github repository</a> or <a href="https://ebookdatasci.com/">visit the production version of our site.</a></p>
</div>
</div>
  </>
  )
}



export default RightBanner