import React from 'react'
import './Bio.css'

function MattBio() {
  return (
    <div className='bio-snippet'>
      <h2>About Matt</h2>
      <p>
      Matt Kownacki graduated from Rutgers University in 2017 with a Bachelors in Mathematics. Matt then attended a Math PhD program at Boston College before taking a leave of absence and officially dropping out once the world collectively went crazy in 2020. Matt spent some amount of time adventuring across the globe before eventually settling down to kickstart his career as a software engineer. He has a few years of experience with various programming languages and is currently working towards an MS in Computer Science at Georgia Tech. While the full details of his resume are too verbose to include within the tiny margins of this bio snippet, you can find out more about him on his <a href="https://github.com/mmk150">Github</a>  and on <a href="https://mkownacki.dev">his personal website</a>. 
      </p>
      <br></br>
    </div>
  )
}

export default MattBio
