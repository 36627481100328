import React from 'react'
import './Bio.css'


function GeorgeBio() {
  return (
    <div className='bio-snippet'>
      <h2>About George</h2>
      <p>
      George Jeffreys received his Mathematics Ph.D. from Boston University in 2022 with a dissertation about the geometry of machine learning. Graduating from Rutgers in 2017 with a Bachelors in Mathematics, he was born and raised in New Jersey and statistical studies indicate that he is one of the only people in history to have enjoyed living there. He has 3 years of coding experience with Python and common data science/machine learning libraries (Pandas, scikit-learn, TensorFlow, Keras, Seaborn, Matplotlib), and has 1-2 years of experience with R, C++, Java, Javascript, and SQL. You can find him on <a href="https://www.linkedin.com/in/george-jeffreys-555718b7/">LinkedIn </a> and <a href="https://github.com/G-Jeffreys">Github</a>.</p>
      <br></br>
    </div>
  )
}

export default GeorgeBio
