import React from 'react'

function LeftBanner() {
  return (
    <>
    <div className='left-banner'>
</div>
  </>
  )
}

export default LeftBanner