import React from 'react'
import './MarketAnalysis.css'
function LeftMarket() {
  return (
    <div className='left-market'>
    <div className='left-market-text'>
    <br></br>
    <h2>Overview of Tech Stack & Methods</h2>
    <br></br>
      <ul className='tech-list'>
        <li>Data analysis: Python (Pandas, Numpy, Matplotlib,Seaborn).</li>
        <li>Backend development: Node.js and Express.js .</li>
        <li>Frontend development: React.js.</li>
        <li>Database: MongoDB for storing user data and our finalized articles.</li>
        <li>Version Control and CI/CD: Git and GitHub Actions.</li>
        <li>Deployment: Docker on a Linux Virtual Machine.</li>
      </ul>
      <br></br>
      <h2>A Short Summary</h2>
      <br></br>
      <ul className='tech-list'>
      <li>Category level trends for weekly and monthly timesteps.</li>
<li>Visualization of market trends over May/June: statistical plots and Word Clouds generated using WordCloud NLP library from tokenized versions of eBook descriptions aggregated across category/subcategory levels.</li>
<li>Subcategory level trends investigated over June.</li>
<li>Visualization of subcategory market trends over June complete with a glimpse into subcategory specific book cover trends(via KMeans).</li> 
        
      </ul>
      <br></br>
      <br></br>
      
      <p className='ad-copy-p'>For a more detailed look, download the report by clicking the image to the right!</p>
    </div>
    </div>
  )
}

export default LeftMarket