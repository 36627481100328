import React from 'react'
import './MarketAnalysis.css'
function RightMarket() {

  function handleDownloadClick() {
    const link = document.createElement('a');
    link.href = '/docs/EBOOKDS-Final.pdf'; // Adjust this to the path of your PDF
    link.download = 'eBook DS Report(May and Jun).pdf'; // Adjust this to the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

    return (
        <div className='right-market'>
        <div className='image-container'>
          <img src='/images/downloadhere.png'onClick={handleDownloadClick}/>
        </div>
        </div>
      )
}

export default RightMarket