import React from 'react'
import {Link} from 'react-router-dom'
import './Footer.css'
function Footer() {
  return (
    <div className='footer-container'>
        <section className="footer-pointer">
            <p className="footer-pointer-heading">
                Consider checking out our <a href="https://ebookdatasci.com">production site </a> as well as the <a href="https://github.com/mmk150/ebookds">Github repository</a> for this project.
            </p>
        </section>       
        </div>
  )
}

export default Footer